import React from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';

import parkImg from '../assets/icons/parkfenntartas.svg';
import koztisztImg from '../assets/icons/koztisztasag.svg';
import varosImg from '../assets/icons/varosuzemeltetes.svg';
import zoldImg from '../assets/icons/zoldterulet.svg';
import szakiparImg from '../assets/icons/szakipar.svg';
import szolgImg from '../assets/icons/szolgaltatas.svg';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const OurTasks = () => {
  return (
    <Wrapper>
      <div className="box-container">
        <div className="left-side">
          <h2>Feladataink</h2>
          <p>Információk és szolgáltatások</p>
        </div>
        <div className="right-side">
          <div className="right-side-top">
            <div className="iconbox">
              <a href="/szolgaltatasok/parkfenntartas">
                <img src={parkImg} alt="Parkfenntartás" />
                <p>Parkfenntartás</p>
              </a>
            </div>
            <div className="iconbox">
              <a href="/szolgaltatasok/koztisztasag">
                <img src={koztisztImg} alt="Köztisztaság" />
                <p>Köztisztaság</p>
              </a>
            </div>
            <div className="iconbox">
              <a href="/szolgaltatasok/varosuzemeltetes">
                <img src={varosImg} alt="Városüzemeltetés" />
                <p>Városüzemeltetés</p>
              </a>
            </div>
          </div>
          <div className="right-side-bottom">
            <div className="iconbox">
              <a href="/szolgaltatasok/zoldteruletfejlesztes">
                <img src={zoldImg} alt="Kiemelt zöldfelületfejlesztés" />
                <p>Kiemelt zöldfelületfejlesztés</p>
              </a>
            </div>
            <div className="iconbox">
              <a href="/szolgaltatasok/szakipar">
                <img src={szakiparImg} alt="Szakipar" />
                <p>Szakipar</p>
              </a>
            </div>
            <div className="iconbox">
              <a href="/szolgaltatasok/szolgaltatas">
                <img src={szolgImg} alt="Szolgáltatás" />
                <p>Szolgáltatás</p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile-box-container">
        <div className="title">
          <div className="top-side">
            <h2>Feladataink</h2>
            <p>Információk és szolgáltatások</p>
          </div>
        </div>
        <div className="swiper-container">
          <Swiper
            slidesPerView={1.5}
            loop={true}
            autoplay={true}
            centeredSlides={true}
            spaceBetween={20}
            grabCursor={true}
            pagination={{
              clickable: true,
              type: 'fraction',
              bulletElement: '',
              renderBullet: function (index, className) {
                return `<span>${index}/</span>`;
              }
            }}
            modules={[Pagination]}>
            <SwiperSlide>
              <div className="iconbox">
                <a href="/szolgaltatasok/parkfenntartas">
                  <img src={parkImg} alt="Parkfenntartás" />
                  <p>Parkfenntartás</p>
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="iconbox">
                <a href="/szolgaltatasok/koztisztasag">
                  <img src={koztisztImg} alt="Köztisztaság" />
                  <p>Köztisztaság</p>
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="iconbox">
                <a href="/szolgaltatasok/varosuzemeltetes">
                  <img src={varosImg} alt="Városüzemeltetés" />
                  <p>Városüzemeltetés</p>
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="iconbox">
                <a href="/szolgaltatasok/zoldteruletfejlesztes">
                  <img src={zoldImg} alt="Kiemelt zöldfelületfejlesztés" />
                  <p>Kiemelt zöldfelületfejlesztés</p>
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="iconbox">
                <a href="/szolgaltatasok/szakipar">
                  <img src={szakiparImg} alt="Szakipar" />
                  <p>Szakipar</p>
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="iconbox">
                <a href="/szolgaltatasok/szolgaltatas">
                  <img src={szolgImg} alt="Szolgáltatás" />
                  <p>Szolgáltatás</p>
                </a>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  width: 100%;
  .mobile-box-container {
    display: none;
  }

  .box-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 35vh;

    .left-side {
      display: flex;
      flex-direction: column;
      width: 40%;

      h2 {
        font-size: clamp(var(--title-font-size), 3vw, 5vw);
      }

      p {
        font-size: var(--paragraph-font-size);
      }
    }

    .right-side {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      width: 60%;
    }

    .right-side-top,
    .right-side-bottom {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }

    .iconbox {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 33.33%;
      height: 100%;

      p {
        font-size: var(--paragraph-font-size);
      }

      img {
        height: 40%;
        width: auto;
        transition: all 0.3s ease-in-out;
        padding: 0.3rem;
      }

      a {
        display: flex;
        height: 16vh;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
        font-weight: 800;
        gap: 1rem;
      }

      :hover img {
        filter: invert(0.5) drop-shadow(6px 5px 3px rgba(0, 0, 0, 0.45));
      }
    }
  }
  @media screen and (max-width: 1024px) {
    width: 100vw;

    .box-container {
      display: none;
    }

    .mobile-box-container {
      display: flex;
      position: relative;
      width: 100%;
      height: 50vh;
      background: transparent;
    }

    .title {
      display: flex;
      width: 96%;
      margin-inline: auto;
      height: 100%;
      padding: 4%;
      box-shadow: 0 16px 40px rgba(0, 0, 0, 0.2);
      z-index: 110;
      border-radius: 56px;
      transform: translateY(-20vh);
      background: var(--primary-bg);
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      text-align: center;
    }

    .swiper-container {
      position: absolute;
      display: flex;

      width: 100%;
      height: 35vh;
      bottom: 45%;
      right: 0;
      z-index: 150;
    }

    .swiper-pagination-horizontal {
      transform: translateY(1rem);
    }
    .swiper-pagination-total {
      color: var(--text-color);
    }
    .swiper-pagination-current {
      font-weight: 800;
    }

    .iconbox {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 30vh;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 4px 0 4px rgba(0, 0, 0, 0.25);
      background: var(--primary-bg);
      border-radius: 25px;
      text-align: center;
      padding: 2rem;

      p {
        font-size: 1.2rem;
      }

      img {
        transition: all 0.3s ease-in-out;
        width: 60px;
      }

      a {
        text-align: center;
        font-weight: 800;
      }
    }
  }
`;

export default OurTasks;
