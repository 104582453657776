import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import axios from 'axios'
import Parser from 'html-react-parser'
import { base_url } from '../helper/urls'
import { motion } from 'framer-motion'

const Palyazat = () => {
  const [data, setData] = useState()
  const [palyazatreszlet, setPalyazatreszlet] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const { id } = useParams()
  const [ujPalyazat, setUjPalyazat] = useState(null);
  const [palyazatreszlets, setPalyazatreszlets] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    const fetchUjPalyazat = async () => {
      try {
        const response = await axios.get(
            `${base_url}/api/uj-palyazats/${id}?populate=palyazatreszlets`
        );
        setUjPalyazat(response.data.data);
        setLoading(false);
      } catch (err) {
        console.error('Hiba történt az uj-palyazat lekérésekor:', err);
        setLoading(false);
      }
    };

    fetchUjPalyazat();
  }, [id]);

  useEffect(() => {
    const fetchPalyazatreszlets = async () => {
      if (ujPalyazat && ujPalyazat.attributes.palyazatreszlets.data.length > 0) {
        try {
          const ids = ujPalyazat.attributes.palyazatreszlets.data.map(item => item.id);

          const response = await axios.get(
              `${base_url}/api/palyazatreszlets?filters[id]=${ids.join('&filters[id]=')}&populate=*`
          );

          setPalyazatreszlets(response.data.data);
          setLoading(false);
        } catch (err) {
          console.error('Hiba történt a palyazatreszletek lekérésekor:', err);
          setLoading(false);
        }
      }
    };

    if (ujPalyazat) {
      fetchPalyazatreszlets();
    }
  }, [ujPalyazat]);

  return (
    <Wrapper>
      <div className='palyazat-fo-tarolo'>
        <h1 className="title">{ujPalyazat ? ujPalyazat.attributes.Cim : 'Betöltés...'}</h1>
            <motion.div
                initial={{opacity: 0}}
                animate={{
                  opacity: 1,
                }}
                transition={{
                  duration: 0.3,
                }}>
              {palyazatreszlets.length > 0 ? (
                  <div>
              {palyazatreszlets.map(function (item, i) {
                return (
                <div className='palyazat-tarolo' key={i}>
                  <h6 className='palyazat-cim'>
                    {Parser(item.attributes.PDF.data.attributes.name)}
                  </h6>

                  <a
                      className='palyazat-letoltes-tarolo'
                      href={item.attributes.PDF.data.attributes.url}
                      target='_blank'
                      rel='noreferrer'>
                    <div className='palyazat-letoltes'>Megtekintés</div>
                  </a>
                </div>
              )
              })}</div>
              ) : (
                  <p>Nincsenek elérhető részletek.</p>)}

              <a className='felhivasok-tarolo' href='/felhivasok'>
                <div className='felhivasok-gomb'>Vissza az pályázatokhoz</div>
              </a>
            </motion.div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  background-color: var(--light-grey);

  .palyazat-fo-tarolo {
    max-width: 1232px;
    width: 64%;
    min-height: calc(100vh - 150px);
    margin-top: 250px;

    .title {
      margin-bottom: 2rem;
    }

    .loading {
      min-height: 100vh;
    }

    .palyazat-tarolo {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      border-radius: 35px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      padding: 3% 8.44%;
      margin-bottom: 1%;
      background-color: var(--primary-bg);
      justify-content: space-between;
    }

    .palyazat-letoltes-tarolo {
      background-color: var(--box-color);
      width: 30%;
      border-radius: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 2%;
      padding-bottom: 2%;
      transition: all 0.3s;
    }

    .palyazat-letoltes-tarolo:hover {
      background-color: var(--box-color-hover);
    }

    .palyazat-letoltes {
      font-size: var(--button-font-size);
      color: var(--text-color);
      font-weight: 700;
    }

    .palyazat-cim {
      width: 70%;
      font-size: var(--title-font-size);
      font-weight: 800;
    }

    .palyazat-tartalom {
      font-size: var(--paragraph-font-size);
    }

    .felhivasok-tarolo {
      background-color: var(--box-color);
      width: 30%;
      border-radius: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 2%;
      padding-bottom: 2%;
      margin: 5% auto;
      transition: all 0.3s;
    }

    .felhivasok-tarolo:hover {
      background-color: var(--box-color-hover);
    }

    .felhivasok-gomb {
      font-size: var(--button-font-size);
      color: var(--text-color);
      font-weight: 700;
    }
  }

  @media screen and (max-width: 1024px) {
    .palyazat-fo-tarolo {
      width: 94%;
      margin-top: 150px;

      .palyazat-cim {
        text-align: center;
      }

      .palyazat-tartalom {
        margin: 2rem auto;
        width: 80%;
      }

      .felhivasok-tarolo {
        width: 80%;
        margin: 1rem auto;
        padding: 1rem 0;
      }
    }
  }
`

export default Palyazat
