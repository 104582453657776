import "./App.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navbar, Footer } from "./components";
import {
  MainPage,
  AboutUs,
  ContactUs,
  Terkepek,
  Hir,
  Hirek,
  Szolgaltatas,
  Palyazat,
  Gazdalkodasi,
  Allas,
  Felhivasok,
  Szerzodesek,
  KozerdekuAdatok,
  KozerdekuAdat,
  Cookies,
  Impressum,
  Error,
  DocumentList,
  Biodiverz,
} from "./pages";
import ScrollToTop from "./helper/ScrollToTop";
import smoothscroll from "smoothscroll-polyfill";
import { GlobalContext } from "./helper/GlobalContext";
import { CookieBanner } from "@keepist/react-gdpr-cookie-banner";

function App() {
  const [navClicked, setNavClicked] = useState(false);
  const [animationCanStart, setAnimationCanStart] = useState(true);
  const [openedPopup, setOpenedPopup] = useState(false);

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollPosition === 0) {
      setTimeout(() => {
        setAnimationCanStart(true);
      }, 100);
    }
    //eslint-disable-next-line
  }, [scrollPosition, window.location.pathname]);

  const clickValue = {
    navClicked,
    setNavClicked,
    animationCanStart,
    setAnimationCanStart,
    openedPopup,
    setOpenedPopup,
  };

  smoothscroll.polyfill();

  return (
    <>
      <Router>
        <GlobalContext.Provider value={clickValue}>
          <Navbar />
          <ScrollToTop>
            <Routes>
              <Route exact path="/" element={<MainPage />} />
              <Route exact path="/rolunk" element={<AboutUs />} />
              <Route exact path="/kapcsolat" element={<ContactUs />} />
              <Route
                exact
                path="/terkepek/kutyapiszokgyujto-edeny"
                element={
                  <Terkepek
                    title="kutyapiszokgyujto-edeny"
                    src="https://www.google.com/maps/d/embed?mid=1VXXltI4LEvtY1DqenwcIfUDJH5ruEhY&ehbc=2E312F"
                  />
                }
              />
              <Route
                exact
                path="/terkepek/jatszoterek"
                element={
                  <Terkepek
                    title="Játszóterek"
                    src="https://www.google.com/maps/d/embed?mid=1C13Fe1i1wN3b3xq1vc17AkXqvf0ckX0&ehbc=2E312F"
                  />
                }
              />
              <Route exact path="/impresszum" element={<Impressum />} />
              <Route exact path="/sutik" element={<Cookies />} />
              <Route exact path="/hirek" element={<Hirek />} />
              <Route exact path="/hirek/:id" element={<Hir />} />
              <Route exact path="/allasok/:id" element={<Allas />} />
              <Route exact path="/palyazatok/:id" element={<Palyazat />} />
              <Route exact path="/felhivasok" element={<Felhivasok />} />
              <Route exact path="/biodiverz" element={<Biodiverz />} />
              <Route
                exact
                path="/szolgaltatasok/:id"
                element={<Szolgaltatas />}
              />
              <Route
                exact
                path="/kozerdeku-adatok"
                element={<KozerdekuAdatok />}
              />
              <Route
                exact
                path="/kozerdeku-adatok/:slug"
                element={<KozerdekuAdat />}
              />
              <Route
                exact
                path="/kozerdeku-adatok/gazdalkodasi"
                element={<Gazdalkodasi />}
              />
              <Route
                exact
                path="/kozerdeku-adatok/gazdalkodasi/:slug"
                element={<KozerdekuAdat />}
              />

              {/* <Route
                exact
                path="/kozerdeku-adatok/gazdalkodasi/szerzodesek"
                element={<Szerzodesek />}
              />
*/}
              <Route
                exact
                path="/kozerdeku-adatok/gazdalkodasi/szerzodesek/:year"
                element={<DocumentList />}
              />
              <Route exact path="*" element={<Error />} />
            </Routes>
          </ScrollToTop>
          <CookieBanner
            message="Ez a weboldal sütiket használ, amelyek segítik a weboldal működését."
            showAcceptSelectionButton
            acceptAllButtonText="Összes elfogadása"
            acceptSelectionButtonText="Kijelöltek elfogadása"
            necessaryOptionText="Oldal működéséhez szükséges"
            preferencesOptionText="Preferenciák"
            statisticsOptionText="Statisztikák"
            marketingOptionText="Marketing"
            privacyPolicyLinkText="Süti tájékoztató"
            policyLink="/sutik"
          />
          <Footer />
        </GlobalContext.Provider>
      </Router>
    </>
  );
}

export default App;
