import React from 'react'
import styled from 'styled-components'
import facebook from '../assets/icons/facebook.svg'
import instagram from '../assets/icons/instagram.svg'
import youtube from '../assets/icons/youtube.svg'

import { motion } from 'framer-motion'

const ContactUs = () => {
  return (
    <Wrapper>
      <motion.div
        className='kapcsolat-fo-tarolo'
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
        }}
        transition={{
          duration: 0.3,
        }}>
        <div className='contact-text'>
          <h1>Kapcsolat</h1>
          <h2>Kispest Közpark</h2>
          <p>1192 Budapest, Bercsényi utca 18.</p>
          <a href='mailto:info@kozpark.hu'>info@kozpark.hu</a>
          <a href='tel:+3612829622'>+36-1-282-9622</a>
          <h3>Kövess minket!</h3>
          <div className='social-icons'>
            <a
              href='https://www.facebook.com/kispestkozpark'
              target='_blank'
              rel='noreferrer'>
              <img src={facebook} alt='facebook' />
            </a>
            <a
              href='https://www.instagram.com/kispest.kozpark'
              target='_blank'
              rel='noreferrer'>
              <img src={instagram} alt='instagram' />
            </a>
            <a
              href='https://www.youtube.com/channel/UCa5lEzRzAcDdaigIKuQP8WA/featured'
              target='_blank'
              rel='noreferrer'>
              <img src={youtube} alt='youtube' />
            </a>
          </div>
        </div>
        <div className='contact-map'>
          <iframe
            width='100%'
            height='600'
            frameBorder='0'
            scrolling='no'
            marginHeight='0'
            marginWidth='0'
            title='map'
            src='https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=1192,%20Budapest,%20Bercs%C3%A9nyi%20u.%2018+(Kispest%20K%C3%B6zpark)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed'></iframe>
        </div>
      </motion.div>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  .kapcsolat-fo-tarolo {
    display: flex;
    flex-direction: row;
    padding-top: 3.5rem;
    justify-content: space-around;
    max-width: 1232px;
    width: 64%;
    align-items: center;
    margin-top: 150px;
    @media screen and (max-width: 1024px) {
      flex-direction: column;
      width: 100%;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin-top: 2.5rem;
    }

    h1 {
      font-size: var(--main-title-font-size);
    }

    h2 {
      font-size: var(--paragraph-font-size);
      text-transform: uppercase;
      padding: 2rem 0;
    }

    h3 {
      color: var(--secondary-bg);
      font-style: italic;
      font-size: var(--button-font-size);
      font-weight: 400;
      padding-top: 2rem;
    }

    p {
      font-size: var(--paragraph-font-size);
      margin-bottom: 1rem;
    }

    a {
      display: block;
      color: var(--text-color);
      font-size: var(--paragraph-font-size);
      margin-bottom: 1rem;
    }

    .contact-text {
      width: 50%;

      @media screen and (max-width: 1024px) {
        width: 100%;
        text-align: center;
      }
    }

    .contact-map {
      width: 710px;

      iframe {
        border-radius: 30px;
      }

      @media screen and (max-width: 1024px) {
        width: 90%;

        iframe {
          height: 450px;
        }
      }
    }

    .social-icons {
      display: flex;
      flex-direction: row;
      padding-top: 1.25rem;
      gap: 1.5rem;

      @media screen and (max-width: 1024px) {
        padding-bottom: 3rem;
        justify-content: center;
      }
    }
  }
`

export default ContactUs
