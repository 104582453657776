import React, { useState, useEffect } from 'react'
import Parser from 'html-react-parser'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import axios from 'axios'
import { base_url } from '../helper/urls'
import bullet from '../assets/bullet.svg'
import circle from '../assets/orange-circle.svg'
import arrowLeft from '../assets/arrow-left.svg'
import arrowRight from '../assets/arrow-right.svg'
import arrowBig from '../assets/arrow-big.svg'
import arrowMobile from '../assets/arrow-mobile.svg'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'

const Szolgaltatas = () => {
  const [data, setData] = useState()
  const [bufferData, setBufferData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isBufferLoading, setIsBufferLoading] = useState(true)
  const [swapImage1, setSwapImage1] = useState('unset')
  const [swapImage2, setSwapImage2] = useState('unset')
  const [changeImage, setChangeImage] = useState(true)
  const { id } = useParams()
  let navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const response = await axios.get(
          `${base_url}/api/szolgaltatasaink?filters[Slug]=${id}&populate=*`
        )
        setData(response.data.data[0])
        setSwapImage1(
          response.data.data[0].attributes.Kiemelt_kep.data.attributes.url
        )
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }

    fetchData()
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      setIsBufferLoading(true)
      try {
        const response = await axios.get(
          `${base_url}/api/szolgaltatasaink?sort=id%3Aasc&populate=*`
        )
        setBufferData(response.data.data)
      } catch (error) {
        console.log(error)
      }
      setIsBufferLoading(false)
    }

    fetchData()
  }, [])

  function handleNavigation(direction) {
    if (!isBufferLoading) {
      if (direction === 'forward') {
        if (data.id < bufferData.length) {
          if (swapImage1 === 'unset') {
            setSwapImage1(
              bufferData[data.id].attributes.Kiemelt_kep.data.attributes.url
            )
            setTimeout(function () {
              setSwapImage2('unset')
            }, 300)
          } else if (swapImage2 === 'unset') {
            setSwapImage2(
              bufferData[data.id].attributes.Kiemelt_kep.data.attributes.url
            )
            setTimeout(function () {
              setSwapImage1('unset')
            }, 300)
          }
        } else {
          if (swapImage1 === 'unset') {
            setSwapImage1(
              bufferData[0].attributes.Kiemelt_kep.data.attributes.url
            )
            setTimeout(function () {
              setSwapImage2('unset')
            }, 300)
          } else if (swapImage2 === 'unset') {
            setSwapImage2(
              bufferData[0].attributes.Kiemelt_kep.data.attributes.url
            )
            setTimeout(function () {
              setSwapImage1('unset')
            }, 300)
          }
        }
      } else if (direction === 'backward') {
        if (data.id > 1) {
          if (swapImage1 === 'unset') {
            setSwapImage1(
              bufferData[data.id - 2].attributes.Kiemelt_kep.data.attributes.url
            )
            setTimeout(function () {
              setSwapImage2('unset')
            }, 300)
          } else if (swapImage2 === 'unset') {
            setSwapImage2(
              bufferData[data.id - 2].attributes.Kiemelt_kep.data.attributes.url
            )
            setTimeout(function () {
              setSwapImage1('unset')
            }, 300)
          }
        } else {
          if (swapImage1 === 'unset') {
            setSwapImage1(
              bufferData[bufferData.length - 1].attributes.Kiemelt_kep.data
                .attributes.url
            )
            setTimeout(function () {
              setSwapImage2('unset')
            }, 300)
          } else if (swapImage2 === 'unset') {
            setSwapImage2(
              bufferData[bufferData.length - 1].attributes.Kiemelt_kep.data
                .attributes.url
            )
            setTimeout(function () {
              setSwapImage1('unset')
            }, 300)
          }
        }
      }

      setTimeout(function () {
        setChangeImage(!changeImage)
        if (direction === 'forward') {
          if (data.id < bufferData.length) {
            setData(bufferData[data.id])
            navigate(`/szolgaltatasok/${bufferData[data.id].attributes.Slug}`)
          } else {
            setData(bufferData[0])
            navigate(`/szolgaltatasok/${bufferData[0].attributes.Slug}`)
          }
        } else if (direction === 'backward') {
          if (data.id > 1) {
            setData(bufferData[data.id - 2])
            navigate(
              `/szolgaltatasok/${bufferData[data.id - 2].attributes.Slug}`
            )
          } else {
            setData(bufferData[bufferData.length - 1])
            navigate(
              `/szolgaltatasok/${
                bufferData[bufferData.length - 1].attributes.Slug
              }`
            )
          }
        }
      }, 300)

      let kep = document.getElementById('szolgaltatas-kep-tarolo')
      let tartalom = document.getElementById('cim-es-tartalom-tarolo')
      let ikon = document.getElementById('ikon')
      let tevekenysegCim = document.getElementById('tevekenyseg-cim')
      let tevekenyseg = document.getElementById('tevekenysegek-fo-tarolo')
      let tovabbiTartalom = document.getElementById(
        'tovabbi-tartalom-fo-tarolo'
      )
      let erdeklodjon = document.getElementById('erdeklodjon-wrapper')

      if (
        kep &&
        tartalom &&
        ikon &&
        tevekenysegCim &&
        tevekenyseg &&
        tovabbiTartalom &&
        erdeklodjon
      ) {
        kep.style.opacity = 0
        tartalom.style.opacity = 0
        ikon.style.opacity = 0
        tevekenysegCim.style.opacity = 0
        tevekenyseg.style.opacity = 0
        tovabbiTartalom.style.opacity = 0
        erdeklodjon.style.opacity = 0
      }
      setTimeout(function () {
        if (
          kep &&
          tartalom &&
          ikon &&
          tevekenysegCim &&
          tevekenyseg &&
          tovabbiTartalom &&
          erdeklodjon
        ) {
          kep.style.opacity = 1
          tartalom.style.opacity = 1
          ikon.style.opacity = 1
          tevekenysegCim.style.opacity = 1
          tevekenyseg.style.opacity = 1
          tovabbiTartalom.style.opacity = 1
          erdeklodjon.style.opacity = 1
        }
      }, 500)
    }
  }

  function arrowHover(arrow, change) {
    if (arrow === 'next') {
      let button = document.getElementById('jobb-nyil')
      let container = document.getElementById('jobb-kor')
      if (change === 'add') {
        button.classList.add('arrow-hover')
        container.classList.add('arrow-hover-container')
      } else {
        button.classList.remove('arrow-hover')
        container.classList.remove('arrow-hover-container')
      }
    } else {
      let button = document.getElementById('bal-nyil')
      let container = document.getElementById('bal-kor')
      if (change === 'add') {
        button.classList.add('arrow-hover')
        container.classList.add('arrow-hover-container')
      } else {
        button.classList.remove('arrow-hover')
        container.classList.remove('arrow-hover-container')
      }
    }
  }

  return (
    <Wrapper>
      <div className='szolgaltatas-fo-tarolo'>
        {isLoading ? (
          <div className='loading'></div>
        ) : (
          <>
            <motion.div
              className='szolgaltatas-kep-tarolo-kulso'
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
              }}
              transition={{
                duration: 0.3,
              }}>
              <div
                className='szolgaltatas-kep-tarolo'
                id='szolgaltatas-kep-tarolo'>
                <img
                  src={changeImage ? swapImage1 : swapImage2}
                  alt={
                    data.attributes.Kiemelt_kep.data.attributes.alternativeText
                  }
                />
              </div>
            </motion.div>
            <motion.div
              className='szolgaltatas-tartalom-tarolo'
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
              }}
              transition={{
                duration: 0.3,
              }}>
              <div className='fejlec-fo-tarolo'>
                <div
                  className='cim-es-tartalom-tarolo'
                  id='cim-es-tartalom-tarolo'>
                  <h1 className='szolgaltatas-cim'>
                    {Parser(data.attributes.Cim)}
                  </h1>
                  <div className='szolgaltatas-tartalom'>
                    {Parser(data.attributes.Tartalom)}
                  </div>
                </div>
                <div className='ikon-korhinta-tarolo'>
                  <div className='ikon-tarolo'>
                    <img
                      src={data.attributes.Ikon.data.attributes.url}
                      alt={data.attributes.Ikon.data.attributes.alternativeText}
                      id='ikon'
                    />
                  </div>
                  <div className='korhinta-lepteto-tarolo'>
                    <div>
                      <img
                        src={circle}
                        alt='circle'
                        className='bal-kor'
                        id='bal-kor'
                        onClick={() => handleNavigation('backward')}
                        onMouseOver={() => arrowHover('prev', 'add')}
                        onMouseOut={() => arrowHover('prev', 'remove')}
                      />
                      <img
                        src={arrowLeft}
                        alt='left arrow'
                        className='bal-nyil'
                        id='bal-nyil'
                        onClick={() => handleNavigation('backward')}
                        onMouseOver={() => arrowHover('prev', 'add')}
                        onMouseOut={() => arrowHover('prev', 'remove')}
                      />
                    </div>
                    <div className='szamolo-tarolo'>
                      <p className='aktualis-oldal'>{data.id}&nbsp;&nbsp;</p>
                      <p className='osszes-oldal'>
                        / {bufferData ? bufferData.length : 6}
                      </p>
                    </div>
                    <div>
                      <img
                        src={circle}
                        alt='circle'
                        className='jobb-kor'
                        id='jobb-kor'
                        onClick={() => handleNavigation('forward')}
                        onMouseOver={() => arrowHover('next', 'add')}
                        onMouseOut={() => arrowHover('next', 'remove')}
                      />
                      <img
                        src={arrowRight}
                        alt='right arrow'
                        className='jobb-nyil'
                        id='jobb-nyil'
                        onClick={() => handleNavigation('forward')}
                        onMouseOver={() => arrowHover('next', 'add')}
                        onMouseOut={() => arrowHover('next', 'remove')}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <h2 className='tevekenyseg-cim' id='tevekenyseg-cim'>
                Tevékenységeink
              </h2>
              <div
                className='tevekenysegek-fo-tarolo'
                id='tevekenysegek-fo-tarolo'>
                <div
                  className={
                    data.attributes.Tevekenyseg_3_opcionalis
                      ? 'tevekenysegek-felso-tarolo ' +
                        (data.attributes.Tevekenyseg_4_opcionalis ||
                        data.attributes.Tevekenyseg_5_opcionalis ||
                        data.attributes.Tevekenyseg_6_opcionalis
                          ? ''
                          : data.attributes.Tovabbi_kep_opcionalis.data ||
                            data.attributes
                              .Tovabbi_tartalom_kep_melle_opcionalis
                          ? 'csak-felso-tarolo'
                          : '')
                      : 'tevekenysegek-felso-tarolo-harmadik-nelkul ' +
                        (data.attributes.Tevekenyseg_4_opcionalis ||
                        data.attributes.Tevekenyseg_5_opcionalis ||
                        data.attributes.Tevekenyseg_6_opcionalis
                          ? ''
                          : data.attributes.Tovabbi_kep_opcionalis.data ||
                            data.attributes
                              .Tovabbi_tartalom_kep_melle_opcionalis
                          ? 'csak-felso-tarolo'
                          : '')
                  }>
                  <div
                    className={
                      'tevekenyseg-tarolo ' +
                      (data.attributes.Tevekenyseg_3_opcionalis
                        ? ''
                        : 'tevekenyseg-tarolo-harmadik-nelkul')
                    }>
                    {Parser(data.attributes.Tevekenyseg_1)}
                  </div>
                  {data.attributes.Tevekenyseg_2_opcionalis && (
                    <div className='tevekenyseg-tarolo tevekenyseg-tarolo-kozepso'>
                      {Parser(data.attributes.Tevekenyseg_2_opcionalis)}
                    </div>
                  )}
                  {data.attributes.Tevekenyseg_3_opcionalis && (
                    <div className='tevekenyseg-tarolo'>
                      {Parser(data.attributes.Tevekenyseg_3_opcionalis)}
                    </div>
                  )}
                </div>
                {(data.attributes.Tevekenyseg_4_opcionalis ||
                  data.attributes.Tevekenyseg_5_opcionalis ||
                  data.attributes.Tevekenyseg_6_opcionalis) && (
                  <div
                    className={
                      data.attributes.Tevekenyseg_6_opcionalis
                        ? 'tevekenysegek-also-tarolo ' +
                          (data.attributes.Tovabbi_kep_opcionalis.data ||
                          data.attributes.Tovabbi_tartalom_kep_melle_opcionalis
                            ? ''
                            : 'nincs-tovabbi-tartalom')
                        : 'tevekenysegek-also-tarolo-harmadik-nelkul ' +
                          (data.attributes.Tovabbi_kep_opcionalis.data ||
                          data.attributes.Tovabbi_tartalom_kep_melle_opcionalis
                            ? ''
                            : 'nincs-tovabbi-tartalom')
                    }>
                    {data.attributes.Tevekenyseg_4_opcionalis && (
                      <div
                        className={
                          'tevekenyseg-tarolo ' +
                          (data.attributes.Tevekenyseg_6_opcionalis
                            ? ''
                            : 'tevekenyseg-tarolo-harmadik-nelkul')
                        }>
                        {Parser(data.attributes.Tevekenyseg_4_opcionalis)}
                      </div>
                    )}
                    {data.attributes.Tevekenyseg_5_opcionalis && (
                      <div className='tevekenyseg-tarolo tevekenyseg-tarolo-kozepso'>
                        {Parser(data.attributes.Tevekenyseg_5_opcionalis)}
                      </div>
                    )}
                    {data.attributes.Tevekenyseg_6_opcionalis && (
                      <div className='tevekenyseg-tarolo'>
                        {Parser(data.attributes.Tevekenyseg_6_opcionalis)}
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div
                className='tovabbi-tartalom-fo-tarolo'
                id='tovabbi-tartalom-fo-tarolo'>
                <div className='tovabbi-kep-tarolo'>
                  {data.attributes.Tovabbi_kep_opcionalis.data && (
                    <img
                      src={
                        data.attributes.Tovabbi_kep_opcionalis.data.attributes
                          .url
                      }
                      alt={
                        data.attributes.Tovabbi_kep_opcionalis.data.attributes
                          .alternativeText
                      }
                    />
                  )}
                </div>
                <div className='tovabbi-szoveg-tarolo'>
                  {data.attributes.Tovabbi_tartalom_kep_melle_opcionalis &&
                    Parser(
                      data.attributes.Tovabbi_tartalom_kep_melle_opcionalis
                    )}
                </div>
              </div>
            </motion.div>
            <motion.div
              className='erdeklodjon-wrapper'
              id='erdeklodjon-wrapper'
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
              }}
              transition={{
                duration: 0.3,
              }}>
              <div className='erdeklodjon-text'>
                <h6>Érdeklődjön</h6>
                <img src={arrowBig} alt='arrow' className='arrowBig' />
                <img src={arrowMobile} alt='arrow' className='arrowMobile' />
                <div className='erdeklodjon-links'>
                  <a href='mailto:info@kozpark.hu'>info@kozpark.hu</a>
                  <a href='tel:+3612829622'>06 1 282 9622</a>
                </div>
              </div>
            </motion.div>
          </>
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  .szolgaltatas-fo-tarolo {
    min-height: calc(100vh - 150px);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 150px;
    .loading {
      min-height: 100vh;
    }
    .szolgaltatas-tartalom-tarolo {
      max-width: 1232px;
      width: 64%;
    }
    .fejlec-fo-tarolo {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .cim-es-tartalom-tarolo {
      width: 60%;
      transition: all 0.3s;
    }
    .korhinta-lepteto-tarolo {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .szamolo-tarolo {
      width: 3.2rem;
      display: flex;
      justify-content: center;
    }
    .szamolo-tarolo .aktualis-oldal {
      font-size: var(--paragraph-font-size);
      color: var(--text-color);
      font-weight: 700;
    }
    .szamolo-tarolo .osszes-oldal {
      font-size: var(--paragraph-font-size);
      color: var(--secondary-bg);
      font-weight: 400;
    }
    .bal-nyil {
      transform: translateX(-30px) translateY(-20px);
    }
    .jobb-nyil {
      transform: translateX(-30px) translateY(-20px);
    }
    .bal-kor {
      transform: translateX(15px);
    }
    .jobb-kor {
      transform: translateX(15px);
    }
    .ikon-korhinta-tarolo {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: -2rem;
      width: 15rem;
    }
    .ikon-tarolo {
      display: flex;
      justify-content: center;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
        rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
      border-radius: 45px;
      height: 12rem;
      width: 12rem;
      padding: 20%;
      margin-bottom: 2rem;
      background-color: var(--primary-bg);
      z-index: 1;
    }
    .ikon-tarolo img {
      width: 100%;
      transition: all 0.3s;
    }
    .tevekenyseg-cim {
      font-size: calc(var(--title-font-size) + 0.75rem);
      font-weight: 800;
      margin-top: 4%;
      margin-bottom: 2.5%;
      transition: all 0.3s;
    }
    .szolgaltatas-kep-tarolo-kulso {
      width: 100%;
    }
    .szolgaltatas-kep-tarolo {
      width: 100%;
      transition: all 0.3s;
    }
    .szolgaltatas-kep-tarolo img {
      width: 100%;
      height: 25rem;
      object-fit: cover;
      @media screen and (max-width: 1024px) {
        height: 11rem;
      }
    }

    .szolgaltatas-cim {
      margin-top: 4%;
      margin-bottom: 4%;
      font-size: var(--main-title-font-size);
      line-height: calc(var(--main-title-font-size) + 5px);
      font-weight: 800;
    }
    .szolgaltatas-tartalom p {
      font-size: var(--paragraph-font-size);
    }
    .szolgaltatas-tartalom {
      font-size: var(--paragraph-font-size);
      ul {
        list-style: none;
      }
      ul li:before {
        content: url(${bullet});
        position: relative;
        top: 6px;
        margin-right: 33px;
      }

      ul li {
        margin-top: 17px;
        margin-bottom: 17px;
        font-weight: 700;
      }

      ol {
        list-style: none !important;
        counter-reset: li;
        margin-left: 45px;
      }

      ol li::before {
        content: counter(li) '.';
        color: var(--box-color);
        font-size: var(--title-font-size);
        display: inline-block;
        width: 2em;
        margin-left: -2.5em;
        margin-right: 38px;
        text-align: right;
        font-weight: 800;
      }

      ol li {
        counter-increment: li;
        margin-top: 0px;
        margin-bottom: 0px;
        font-weight: 700;
      }
    }
    .text-small {
      font-size: var(--small-text-font-size);
    }
    .tevekenysegek-fo-tarolo {
      display: flex;
      flex-direction: column;
      transition: all 0.3s;
    }
    .tevekenysegek-felso-tarolo {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .tevekenysegek-felso-tarolo-harmadik-nelkul {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
    .csak-felso-tarolo {
      margin-bottom: 5rem;
    }
    .tevekenysegek-also-tarolo {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 3rem;
      margin-bottom: 5rem;
    }
    .tevekenysegek-also-tarolo-harmadik-nelkul {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-top: 3rem;
      margin-bottom: 5rem;
    }
    .nincs-tovabbi-tartalom {
      margin-bottom: 0;
    }
    .tevekenyseg-tarolo {
      display: flex;
      flex-direction: column;
      background-color: var(--box-color);
      min-height: 22rem;
      width: 30%;
      padding: 5%;
      border-radius: 56px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
        rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
      ul {
        margin-left: 2.5rem;
        li {
          color: #fff;
        }
      }
    }
    .tevekenyseg-tarolo-harmadik-nelkul {
      margin-right: 4rem;
    }
    .tevekenyseg-tarolo p {
      font-size: var(--small-text-font-size);
      color: var(--primary-bg);
      font-weight: 700;
    }
    .tovabbi-tartalom-fo-tarolo {
      display: flex;
      flex-direction: row;
      transition: all 0.3s;
    }
    .tovabbi-kep-tarolo {
      width: 65%;
    }
    .tovabbi-szoveg-tarolo {
      width: 35%;
      padding-left: 5%;
    }
    .tovabbi-szoveg-tarolo .text-big {
      font-size: calc(var(--title-font-size) - 0.2rem);
    }
    .tovabbi-szoveg-tarolo p {
      font-size: var(--paragraph-font-size);
    }
    .tovabbi-kep-tarolo img {
      width: 100%;
      object-fit: cover;
      border-radius: 40px;
    }
    .arrow-hover {
      cursor: pointer;
      filter: brightness(0) saturate(100%) invert(19%) sepia(6%) saturate(137%)
        hue-rotate(22deg) brightness(96%) contrast(85%);
    }
    .arrow-hover-container {
      cursor: pointer;
    }
  }

  .erdeklodjon-wrapper {
    background-color: var(--banner-color);
    width: 100%;
    margin: 7.5rem 0 3rem 0;
    transition: all 0.3s;
  }

  .erdeklodjon-text {
    width: 64%;
    margin: auto;
    padding: 10rem;

    h6 {
      color: var(--primary-bg);
    }

    a {
      color: var(--primary-bg);
    }
  }

  @media screen and (max-width: 1024px) {
    .szolgaltatas-fo-tarolo {
      margin-top: 0;

      .szolgaltatas-tartalom-tarolo {
        flex-direction: column;
        width: 96%;
      }

      .cim-es-tartalom-tarolo {
        order: 2;
        width: 100%;

        h1 {
          margin-top: 2rem;
          font-size: calc(var(--main-title-font-size) - 0.5rem);
        }
      }

      .ikon-korhinta-tarolo {
        margin: auto;
        margin-top: -80px;
        justify-content: center;
      }

      .korhinta-lepteto-tarolo {
        position: absolute;
        width: 100%;
        justify-content: space-between;
        margin-top: 1rem;
      }

      .fejlec-fo-tarolo {
        flex-direction: column;
        justify-content: center;
      }

      .szamolo-tarolo {
        margin-top: 13rem;
      }

      .jobb-kor,
      .bal-kor {
        height: 48px;
      }

      .bal-nyil {
        transform: translateX(-23px) translateY(-13px);
      }
      .jobb-nyil {
        transform: translateX(-23px) translateY(-13px);
      }
      .tevekenysegek-felso-tarolo,
      .tevekenysegek-also-tarolo {
        flex-direction: column;
      }

      .tevekenysegek-also-tarolo-harmadik-nelkul.nincs-tovabbi-tartalom,
      .tevekenysegek-also-tarolo-harmadik-nelkul,
      .tevekenysegek-felso-tarolo-harmadik-nelkul {
        display: flex;
        flex-direction: column;
        margin-top: 0;
      }

      .tevekenysegek-also-tarolo {
        margin-top: 0;
      }

      .tevekenyseg-tarolo {
        width: 100%;
        padding: 3rem 2rem;
        margin-bottom: 1rem;
      }

      .tovabbi-tartalom-fo-tarolo {
        flex-direction: column;
      }

      .tovabbi-kep-tarolo {
        width: 100%;
      }

      .tovabbi-szoveg-tarolo {
        width: 100%;
        padding-left: 0;
      }
    }
  }
  .erdeklodjon-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80%;
    justify-content: center;

    h6 {
      font-size: var(--main-title-font-size);
    }

    .arrowBig {
      display: block;
      margin: 0 2rem;
    }

    .arrowMobile {
      display: none;
    }

    .erdeklodjon-links {
      display: flex;
      flex-direction: column;

      a {
        font-size: var(--title-font-size);
        font-weight: bold;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .erdeklodjon-wrapper {
      margin: 5rem 0 3rem 0;
    }
    .erdeklodjon-text {
      flex-direction: column;
      padding: 5rem;

      .arrowBig {
        display: none;
      }

      .arrowMobile {
        display: block;
      }
    }

    .szolgaltatas-fo-tarolo {
      .tevekenyseg-cim {
        font-size: calc(var(--title-font-size) - 0.5rem);
      }

      .tovabbi-szoveg-tarolo {
        line-height: 2rem;
        margin-top: 1rem;
      }
    }

    .erdeklodjon-links {
      text-align: center;
    }
  }
`

export default Szolgaltatas
