import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import logo from "../assets/logo.svg";
import scrollToTop from "../assets/scroll-to-top.svg";
import facebook from "../assets/icons/facebook.svg";
import instagram from "../assets/icons/instagram.svg";
import youtube from "../assets/icons/youtube.svg";

const Footer = () => {
  return (
    <Wrapper>
      <div className="footer-tarolo">
        <div className="footer-top">
          <div className="logo-tarolo">
            <a href="/">
              <img src={logo} alt="logo" />
            </a>
          </div>
          <div>
            <ul>
              <li className="lista-cim">Oldaltérkép</li>
              <NavLink to="/">
                <li>Főoldal</li>
              </NavLink>

              <NavLink to="/rolunk">
                <li>Rólunk</li>
              </NavLink>

              <NavLink to="/hirek">
                <li>Hírek</li>
              </NavLink>

              <NavLink to="/kapcsolat">
                <li>Kapcsolat</li>
              </NavLink>

              <NavLink to="/felhivasok">
                <li>Állások</li>
              </NavLink>

              <NavLink to="/kozerdeku-adatok">
                <li>Közérdekű információk</li>
              </NavLink>

              <NavLink to="/terkepek/kutyapiszokgyujto-edeny">
                <li>Térkép - kutyapiszokgyűjtő edény</li>
              </NavLink>

              <NavLink to="/terkepek/jatszoterek">
                <li>Térkép - játszóterek</li>
              </NavLink>
            </ul>
          </div>
          <div>
            <ul>
              <li className="lista-cim">Feladataink</li>
              <li>
                <a href="/szolgaltatasok/parkfenntartas">Parkfenntartás</a>
              </li>
              <li>
                <a href="/szolgaltatasok/koztisztasag">Köztisztaság</a>
              </li>
              <li>
                <a href="/szolgaltatasok/varosuzemeltetes">Városüzemeltetés</a>
              </li>
              <li>
                <a href="/szolgaltatasok/zoldteruletfejlesztes">
                  Kiemelt zöldfelületfejlesztés
                </a>
              </li>
              <li>
                <a href="/szolgaltatasok/szakipar">Szakipar</a>
              </li>
              <li>
                <a href="/szolgaltatasok/szolgaltatas">Szolgáltatás</a>
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li className="lista-cim">Kövessen minket!</li>
            </ul>

            <div className="kozossegi-media-ikonok">
              <a
                href="https://www.facebook.com/kispestkozpark"
                target="_blank"
                rel="noreferrer"
              >
                <img src={facebook} alt="facebook" />
              </a>
              <a
                href="https://www.instagram.com/kispest.kozpark"
                target="_blank"
                rel="noreferrer"
              >
                <img src={instagram} alt="instagram" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCa5lEzRzAcDdaigIKuQP8WA/featured"
                target="_blank"
                rel="noreferrer"
              >
                <img src={youtube} alt="youtube" />
              </a>
            </div>
          </div>
          <div>
            <ul className="jogi-dokumentumok">
              <li className="lista-cim">Jogi dokumentumok</li>
              <li>
                <a href="/impresszum">Impresszum</a>
              </li>
              <li>
                <a href="/sutik">Cookie policy</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-bottom">
          <p>Copyright Kispest Közpark Kft. Minden jog fenntartva!</p>

          <img
            src={scrollToTop}
            alt="scroll to top"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          />
        </div>
      </div>

      <div className="mobil-footer-tarolo">
        <div className="logo-tarolo">
          <a href="/">
            <img src={logo} alt="logo" />
          </a>
        </div>
        <div className="mobil-linkek">
          <div>
            <ul>
              <li className="lista-cim">Oldaltérkép</li>
              <NavLink to="/">
                <li>Főoldal</li>
              </NavLink>

              <NavLink to="/rolunk">
                <li>Rólunk</li>
              </NavLink>

              <NavLink to="/hirek">
                <li>Hírek</li>
              </NavLink>

              <NavLink to="/kapcsolat">
                <li>Kapcsolat</li>
              </NavLink>

              <NavLink to="/felhivasok">
                <li>Állások</li>
              </NavLink>

              <NavLink to="/kozerdeku-adatok">
                <li>Közérdekű információk</li>
              </NavLink>
            </ul>
          </div>
          <div>
            <ul>
              <li className="lista-cim">Feladataink</li>
              <li>
                <a href="/szolgaltatasok/parkfenntartas">Parkfenntartás</a>
              </li>
              <li>
                <a href="/szolgaltatasok/koztisztasag">Köztisztaság</a>
              </li>
              <li>
                <a href="/szolgaltatasok/varosuzemeltetes">Városüzemeltetés</a>
              </li>
              <li>
                <a href="/szolgaltatasok/zoldteruletfejlesztes">
                  Kiemelt zöldfelületfejlesztés
                </a>
              </li>
              <li>
                <a href="/szolgaltatasok/szakipar">Szakipar</a>
              </li>
              <li>
                <a href="/szolgaltatasok/szolgaltatas">Szolgáltatás</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="mobil-ikonok">
          <ul>
            <li className="lista-cim">Kövessen minket!</li>
          </ul>
          <div className="kozossegi-media-ikonok">
            <a
              href="https://www.facebook.com/kispestkozpark"
              target="_blank"
              rel="noreferrer"
            >
              <img src={facebook} alt="facebook" />
            </a>
            <a
              href="https://www.instagram.com/kispest.kozpark"
              target="_blank"
              rel="noreferrer"
            >
              <img src={instagram} alt="instagram" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCa5lEzRzAcDdaigIKuQP8WA/featured"
              target="_blank"
              rel="noreferrer"
            >
              <img src={youtube} alt="youtube" />
            </a>
          </div>
        </div>
        <div>
          <ul className="jogi-dokumentumok">
            <li>
              <a href="/impresszum">Impresszum</a>
            </li>
            <li>
              <a href="/sutik">Cookie policy</a>
            </li>
          </ul>
        </div>
        <div className="footer-bottom">
          <p>Copyright Kispest Közpark Kft. Minden jog fenntartva!</p>

          <img
            src={scrollToTop}
            alt="scroll to top"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          />
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.footer`
  display: flex;
  justify-content: center;
  padding-top: 7rem;
  .footer-tarolo {
    max-width: 1232px;
    width: 64%;
    .footer-top {
      border-top: solid 2px #d4d4d4;
      border-bottom: solid 2px #d4d4d4;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 3%;
      padding-bottom: 7%;
      .logo-tarolo {
        width: 20%;
      }
      ul {
        list-style-type: none;
        padding-top: 1rem;
      }
      li {
        font-size: var(--small-text-font-size);
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
      }
      li a,
      a li {
        text-decoration: none;
        color: var(--text-color);
      }
      li a:hover,
      a li:hover {
        color: var(--box-color);
      }

      .lista-cim {
        font-weight: 700;
      }
      .kozossegi-media-ikonok {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 0.75rem;
      }
      .kozossegi-media-ikonok a {
        height: 30%;
      }
      .jogi-dokumentumok .lista-cim {
        visibility: hidden;
      }
    }
    .footer-bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-top: 2%;
      padding-bottom: 2%;
      p {
        font-size: var(--small-text-font-size);
      }
      img:hover {
        cursor: pointer;
      }
    }
  }

  .mobil-footer-tarolo {
    display: none;
  }

  @media screen and (min-width: 1025px) and (max-width: 1600px) {
    .footer-tarolo .footer-top li {
      font-size: calc(var(--small-text-font-size) - 0.12rem);
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
    }
    .kozossegi-media-ikonok a {
      height: 22%;
    }
  }

  @media screen and (max-width: 1024px) {
    padding-top: 3rem;
    margin-right: 3%;
    margin-left: 3%;
    .footer-tarolo {
      display: none;
    }

    .mobil-footer-tarolo {
      width: 100%;
      display: block;
      border-top: solid 2px #d4d4d4;
    }

    .logo-tarolo {
      padding-top: 1.5rem;
    }

    ul {
      list-style-type: none;
      padding-top: 1rem;
    }
    li {
      font-size: var(--small-text-font-size);
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      padding-right: 2rem;
    }
    li a,
    a li {
      text-decoration: none;
      color: var(--text-color);
    }
    li a:hover,
    a li:hover {
      color: var(--box-color);
    }

    .mobil-linkek {
      display: flex;
      flex-direction: row;
      padding-top: 2.5rem;
      padding-bottom: 2rem;
      border-bottom: solid 2px #d4d4d4;
    }

    .lista-cim {
      font-weight: 700;
    }

    .kozossegi-media-ikonok {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      border-bottom: solid 2px #d4d4d4;

      img {
        padding-right: 1.5rem;
      }
    }
    .kozossegi-media-ikonok a {
      height: 30%;
    }

    .jogi-dokumentumok {
      display: flex;
      flex-direction: row;
      padding-bottom: 1.5rem;
      padding-bottom: 1.75rem;
      border-bottom: solid 2px #d4d4d4;

      li:first-child {
        padding-right: 6rem;
      }
    }
    .footer-bottom {
      p {
        border-bottom: solid 2px #d4d4d4;
        padding-top: 1.5rem;
        padding-bottom: 1.75rem;
      }

      img {
        display: flex;
        margin: auto;
        padding-top: 1.5rem;
        padding-bottom: 1.75rem;
      }
    }
  }
`;

export default Footer;
