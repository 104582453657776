import React from "react";
import styled from "styled-components";
import backgroundImg from "../assets/hero-bg-full.webp";
import backgroundImgMobile from "../assets/hero-mobile.webp";
import { motion } from "framer-motion";

const Hero = () => {
  return (
    <Wrapper>
      <motion.div
        className="hero-bg-img"
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
        }}
        transition={{
          duration: 0.3,
        }}
      ></motion.div>
      <div className="hero-bg-img__mobile"></div>

      <motion.div
        className="content-wrapper"
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
        }}
        transition={{
          duration: 0.3,
        }}
      >
        <div className="hero-content">
          <h1 className="main-title">A KISPEST KÖZPARK Nonprofit Kft.</h1>
          <p className="main-text">
            Kispest Város közterületeinek fenntartásával és fejlesztésével
            kapcsolatos feladatokat ellátó társaság.
          </p>
          <div className="btn-container">
            <a className="btn btn-left" href="/szolgaltatasok/parkfenntartas">
              Feladataink
            </a>
            <a className="btn btn-right" href="/rolunk">
              Rólunk
            </a>
          </div>
        </div>
      </motion.div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  background-color: var(--primary-bg);
  z-index: 10;
  min-height: 100vh;
  @media screen and (max-width: 1024px) {
    min-height: calc(100vh + 40px);
  }
  .hero-bg-img {
    display: flex;
    background: url(${backgroundImg}) no-repeat top center/cover;
    z-index: -1;
    width: 100vw;
    min-height: 100vh;
    top: 0;
    left: 0;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  .content-wrapper {
    display: flex;
    position: absolute;
    left: 0;
    top: 150px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100vw;
    height: calc(100vh - 150px);
    z-index: 100;
    color: var(--text-color);
  }

  .hero-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 50%;
    height: 100%;
    margin-top: 5%;
    margin-left: 18%;

    .main-title {
      width: 60%;
      font-size: calc(var(--main-title-font-size) - 0.5rem);
      font-weight: 800;
      line-height: 1.1;
      /* text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25); */
    }

    .main-text {
      width: 60%;
      font-size: var(--paragraph-font-size);
      padding-block: 1rem;
    }
  }

  .btn-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 60%;
    gap: 1.5rem;
  }

  .btn {
    width: 100%;
    border-radius: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2%;
    padding-bottom: 2%;
    margin-top: 5%;
    transition: all 0.3s;
    font-size: var(--button-font-size);
    color: var(--text-color);
    font-weight: 700;
    border: 3px solid var(--box-color);
  }

  .btn-left {
    background-color: var(--box-color);
  }

  .btn-right {
    background-color: var(--primary-bg);
  }

  .btn-left:hover {
    background-color: var(--box-color-hover);
    border-color: var(--box-color-hover);
  }

  .btn-right:hover {
    background-color: var(--box-color-hover);
    border-color: var(--box-color-hover);
  }

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    .hero-bg-img {
      display: none;
    }

    .hero-bg-img__mobile {
      position: absolute;
      left: 0;
      top: 80%;
      background: url(${backgroundImgMobile}) no-repeat top center/cover;
      order: 2;
      width: 100vw;
      min-height: 336px;
      left: 0;
    }

    .content-wrapper {
      width: 100%;
      margin-top: 0;
      height: calc(60% - 100px);
      align-items: center;
      z-index: 100;
      color: var(--text-color);
    }
    .hero-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding-inline: 3%;
      margin-top: 1rem;
      margin-left: 0;
      order: 1;

      .main-title {
        width: 100%;
        text-align: center;
        font-size: var(--main-title-font-size);
        font-weight: 800;
        line-height: 1.1;
      }

      .main-text {
        text-align: center;
        width: 100%;
        font-size: var(--paragraph-font-size);
        padding-block: 1rem;
      }

      .btn-container {
        flex-direction: column;
        width: 100%;
        gap: 0;
      }
    }
  }
`;

export default Hero;
