import React from 'react'
import styled from 'styled-components'

const Cookies = () => {
  return (
    <Wrapper>
      <div className='cookie-fo-tarolo'>
        <div>
          <h1>A süti szabályzatról</h1>
          <span>
            Ez a Cookie-szabályzat elmagyarázza, hogy mik azok a cookie-k, és
            hogyan használjuk őket, milyen típusú cookie-kat használunk, azaz
            milyen információkat gyűjtünk a cookie-k segítségével, és hogyan
            használják fel ezeket az információkat, valamint hogyan
            szabályozhatjuk a cookie-beállításokat. Az Ön személyes adatainak
            felhasználásával, tárolásával és biztonságos kezelésével kapcsolatos
            további információkért tekintse meg Adatvédelmi szabályzatunkat.
          </span>
          <span>
            Tudjon meg többet arról, hogy kik vagyunk, hogyan léphet kapcsolatba
            velünk, és hogyan dolgozzuk fel a személyes adatokat az Adatvédelmi
            szabályzatunkban.
          </span>
          <span>
            Az Ön hozzájárulása a következő domainekre vonatkozik:
            kispest-kozpark.hu
          </span>
        </div>
        <div>
          <h2>Mik azok a sütik?</h2>
          <span>
            A cookie-k kisméretű szöveges fájlok, amelyek kisméretű információk
            tárolására szolgálnak. Az Ön eszközén tárolódnak, amikor a webhely
            betöltődik a böngészőjébe. Ezek a cookie-k segítenek abban, hogy a
            weboldal megfelelően működjön, biztonságosabbá tegyük, jobb
            felhasználói élményt nyújtsunk, megértsük, hogyan működik a
            weboldal, és elemezzük, hogy mi működik, és hol van szükség
            fejlesztésre.
          </span>
        </div>
        <div>
          <h2>Hogyan használjuk a sütiket?</h2>
          <span>
            A legtöbb online szolgáltatáshoz hasonlóan weboldalunk is több
            célból belső és harmadik féltől származó sütiket használ. A belső
            cookie-k többnyire szükségesek a webhely megfelelő működéséhez, és
            nem gyűjtik az Ön személyazonosításra alkalmas adatait.
          </span>
          <span>
            A weboldalunkon használt harmadik féltől származó cookie-k főként a
            webhely működésének megértését, a webhelyünkkel való interakcióját,
            szolgáltatásaink biztonságának megőrzését, az Ön számára releváns
            hirdetések megjelenítését, valamint összességében jobb és jobb
            felhasználó biztosítását szolgálják. tapasztalatot szerezzen, és
            segítse felgyorsítani a webhelyünkkel való jövőbeni interakcióit.
          </span>
        </div>
        <div className='cookie-tipusok'>
          <h2>Milyen típusú sütiket használunk?</h2>
          <span>
            A legtöbb online szolgáltatáshoz hasonlóan weboldalunk is több
            célból belső és harmadik féltől származó sütiket használ. A belső
            cookie-k többnyire szükségesek a webhely megfelelő működéséhez, és
            nem gyűjtik az Ön személyazonosításra alkalmas adatait.
          </span>
          <div>
            <span className='cookie-tipus'>Alapvető: </span>
            <span>
              Egyes cookie-k elengedhetetlenek ahhoz, hogy Ön megtapasztalhassa
              oldalunk teljes funkcionalitását. Lehetővé teszik számunkra a
              felhasználói munkamenetek fenntartását és a biztonsági
              fenyegetések megelőzését. Nem gyűjtenek és nem tárolnak személyes
              adatokat. Például ezek a cookie-k lehetővé teszik, hogy
              bejelentkezzen fiókjába, termékeket helyezzen a kosarába, és
              biztonságosan fizessen ki.
            </span>
          </div>
          <div>
            <span className='cookie-tipus'>Statisztikai: </span>
            <span>
              Ezek a cookie-k olyan információkat tárolnak, mint a webhely
              látogatóinak száma, az egyedi látogatók száma, a webhely mely
              oldalait látogatták meg, a látogatás forrása stb. Ezek az adatok
              segítenek megérteni és elemezni, hogy a webhely milyen jól
              teljesít. és hol kell javítani.
            </span>
          </div>
          <div>
            <span className='cookie-tipus'>Marketing: </span>
            <span>
              Weboldalunkon hirdetések jelenhetnek meg. Ezeket a sütiket arra
              használjuk, hogy személyre szabjuk az Ön számára megjelenített
              hirdetéseket, hogy azok értelmesek legyenek az Ön számára. Ezek a
              cookie-k segítenek nyomon követni ezen hirdetési kampányok
              hatékonyságát. Az ezekben a cookie-kban tárolt információkat a
              külső hirdetésszolgáltatók is felhasználhatják arra, hogy
              hirdetéseket jelenítsenek meg a böngészőben más webhelyeken is.
            </span>
          </div>
          <div>
            <span className='cookie-tipus'>Funkcionális: </span>
            <span>
              Ezek azok a cookie-k, amelyek bizonyos nem alapvető funkciókat
              segítenek webhelyünkön. Ezek a funkciók magukban foglalják a
              tartalom, például videók beágyazását vagy a webhely tartalmának
              megosztását a közösségi média platformokon.
            </span>
          </div>
          <div>
            <span className='cookie-tipus'>Preferált: </span>
            <span>
              Ezek a cookie-k segítenek abban, hogy eltároljuk az Ön
              beállításait és böngészési preferenciáit, például a nyelvi
              beállításokat, hogy jobb és hatékonyabb élményben legyen része a
              webhely jövőbeli látogatásai során.
            </span>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  .cookie-fo-tarolo {
    margin-top: 250px;
    width: 64%;

    > div {
      margin-bottom: 3rem;
    }
  }

  .cookie-tipusok > div {
    padding-top: 2rem;
    .cookie-tipus {
      font-weight: 700;
    }
  }

  @media screen and (max-width: 1024px) {
    .cookie-fo-tarolo {
      width: 94%;
      margin-top: 5rem;
    }
  }
`

export default Cookies
