import React, { useState, useEffect } from 'react';
import Parser from 'html-react-parser';
import styled from "styled-components";
import { base_url } from "../helper/urls";
import {useParams, useNavigate, NavLink} from 'react-router-dom';
import axios from 'axios';

const DocumentList = () => {
    const { year } = useParams();
    const [documents, setDocuments] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [yearsAndDocs, setYearsAndDocs] = useState([]);

    useEffect(() => {
        const fetchYearsAndDocuments = async () => {
            try {
                const response = await axios.get(`${base_url}/api/documents?populate=*`);

                const data = response?.data?.data || [];
                setYearsAndDocs(data);

                const yearDocuments = data.filter(doc => doc.attributes.year?.data?.attributes.Cím === year);
                setDocuments(yearDocuments);

            } catch (error) {
                console.error('Hiba az adatok lekérésekor:', error.response ? error.response.data : error.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchYearsAndDocuments();
    }, [year]);

    return (
        <Wrapper>
            <div className='kozerdeku-adatok-fo-tarolo'>
                {isLoading ? (
                    <div className='loading'></div>
                ) : documents.length === 0 ? (
                    <p>Nincsenek dokumentumok ehhez az évhez.</p>
                ) : (
                    documents.map((doc, index) => (
                        <div className='kozerdeku-adat-tarolo' key={index}>
                            <h2 className='kozerdeku-adat-cim'>
                                {Parser(doc.attributes.Cím)}
                            </h2>
                            {doc.attributes.PDF && (
                                <a
                                    className='kozerdeku-adat-letoltes-tarolo'
                                    href={doc.attributes.PDF.data.attributes.url}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    <div className='kozerdeku-adat-letoltes'>Megtekintés</div>
                                </a>
                            )}
                        </div>
                    ))
                )}
                <div className='vissza-a-kozerdeku-adatokhoz-fo-tarolo'>
                    <NavLink
                        className='vissza-a-kozerdeku-adatokhoz-tarolo'
                        to='/kozerdeku-adatok/gazdalkodasi/szerzodesek'>
                        <div className='vissza-a-kozerdeku-adatokhoz-gomb'>
                            Vissza a szerződésekhez
                        </div>
                    </NavLink>
                </div>
            </div>
        </Wrapper>
    );

};

const Wrapper = styled.main`
    background-color: var(--light-grey);

    .kozerdeku-adatok-fo-tarolo {
        max-width: 1232px;
        width: 64%;
        margin-top: 250px;

        .loading {
            min-height: 100vh;
        }

        .kozerdeku-adat-focim,
        .palyazataink-focim {
            margin-bottom: 2%;
            font-size: var(--main-title-font-size);
            font-weight: 800;
        }

        .kozerdeku-adat-tarolo {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            border-radius: 35px;
            box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
            padding: 3% 8.44%;
            background-color: var(--primary-bg);
            margin-bottom: 7%;
        }

        .kozerdeku-adat-cim {
            width: 70%;
            font-size: var(--title-font-size);
            font-weight: 800;
        }

        .kozerdeku-adat-letoltes-tarolo {
            background-color: var(--box-color);
            width: 30%;
            border-radius: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 2%;
            padding-bottom: 2%;
            transition: all 0.3s;
        }

        .kozerdeku-adat-letoltes-tarolo:hover {
            background-color: var(--box-color-hover);
        }

        .kozerdeku-adat-letoltes {
            font-size: var(--button-font-size);
            color: var(--text-color);
            font-weight: 700;
        }
    }

    .vissza-a-kozerdeku-adatokhoz-tarolo {
        background-color: var(--box-color);
        width: 40%;
        border-radius: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 6rem;
        padding-top: 2%;
        padding-bottom: 2%;
        transition: all 0.3s;
    }

    .vissza-a-kozerdeku-adatokhoz-tarolo:hover {
        background-color: var(--box-color-hover);
    }

    .vissza-a-kozerdeku-adatokhoz-gomb {
        font-size: var(--button-font-size);
        color: var(--text-color);
        font-weight: 700;
    }

    .text-small {
        font-size: var(--small-text-font-size);
    }

    .vissza-a-kozerdeku-adatokhoz-fo-tarolo {
        display: flex;
        justify-content: center;
    }

    @media screen and (max-width: 1024px) {
        .kozerdeku-adatok-fo-tarolo {
            width: 94%;
            margin-top: 2.5rem;

            .kozerdeku-adat-focim,
            .palyazataink-focim {
                font-size: var(--title-font-size);
                text-align: center;
                margin-bottom: 1.5rem;
            }

            .kozerdeku-adat-tarolo {
                flex-direction: column;
                padding: 1.5rem;
                margin-bottom: 1.5rem;
                margin-top: 1.5rem;

                .kozerdeku-adat-cim {
                    font-size: var(--small-text-font-size);
                    width: 100%;
                    text-align: center;
                }

                .kozerdeku-adat-letoltes-tarolo {
                    width: 80%;
                    margin-top: 1rem;
                    padding: 1rem 0;
                }
            }
        }

        .vissza-a-kozerdeku-adatokhoz-tarolo {
            width: 100%;
            margin: 3rem 0;
            padding: 1rem 0;
        }
    }
`;

export default DocumentList;