import React from 'react'
import styled from 'styled-components'

const Terkepek = ({title, src}) => {
    return (
      <Wrapper className="map-wrapper">
        <iframe 
          title={title}
          src={src}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          width="1232" 
          height="600">
        </iframe>
      </Wrapper>
    );
  };

  const Wrapper = styled.main`
    max-width: 1232px;
    width: 64%;
    min-height: calc(100vh - 150px);
    margin: auto;
    margin-top: 250px;
    position: relative;

    @media screen and (max-width: 1024px) {
      width: 94%;
      margin-top: 250px;
    }

     @media (max-width: 1024px) {
      max-width: 100%;
      width: 94%;
      height: 65vh;
      margin-top: 150px;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0; 
    }
    `

  export default Terkepek;