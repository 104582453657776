import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Hero from '../components/Hero'
import OurTasks from '../components/OurTasks'
import NewsCarousel from '../components/NewsCarousel'
import Popup from '../components/Popup'
import { motion } from 'framer-motion'
import worker from '../assets/worker.webp'
import bg from '../assets/images/rolunk-tree.svg'

const MainPage = () => {
  return (
    <Wrapper>
      <Hero />
      <Popup />
      <div className='feladat-wrapper'>
        <motion.div
          className='feladat-container-desktop'
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
          }}
          transition={{
            duration: 0.3,
          }}>
          <OurTasks />
        </motion.div>
      </div>
      <motion.div
        className='main-content-wrapper'
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
        }}
        transition={{
          duration: 0.3,
        }}>
        <div className='content'>
          <div className='content-left'>
            <h2>Közös ügyünk Kispest tisztasága és rendezettsége</h2>
            <a className='btn btn-left' href='/felhivasok'>
              Pályázatok
            </a>
            <img className='bg' src={bg} alt='background' />
          </div>
          <div className='content-right'>
            <img src={worker} alt='' />
          </div>
        </div>
      </motion.div>

      <NewsCarousel />
    </Wrapper>
  )
}

const Wrapper = styled.main`
  background-color: var(--light-grey);

  .feladat-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
  .main-content-wrapper {
    max-width: 1232px;
    width: 64%;
    height: 65vh;
    margin-top: 18%;

    @media screen and (max-width: 1024px) {
      padding-top: 30%;
    }
  }

  .content {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 10%;
  }

  .feladat-container-mobile {
    display: none;
  }

  .feladat-container-desktop {
    position: absolute;
    bottom: -5%;
    left: 50%;
    transform: translate(-50%, 50%);
    width: 64%;
    max-width: 1232px;
    height: 46%;
    background-color: white;
    padding: 4%;
    box-shadow: 0 16px 40px rgba(0, 0, 0, 0.2);
    z-index: 110;
    border-radius: 56px;

    @media screen and (max-width: 1024px) {
      position: relative;
      bottom: 0;
      left: 0;
      transform: translate(0);
      width: 100vw;
      max-width: 100vw;
      height: 0%;
      padding: 0;
      box-shadow: none;
      background-color: transparent;
      transform: translateY(0);
    }
  }

  .content-left {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: auto;
    h2 {
      font-size: clamp(2.5rem, 2.5vw, 10vw);
      line-height: 1;
    }
  }

  .content-right {
    width: 50%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    img {
      border-radius: 35px;
      width: 100%;
      box-shadow: 8px 10px 28px rgba(0, 0, 0, 0.15);
    }
  }

  .btn {
    width: 70%;
    border-radius: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2%;
    padding-bottom: 2%;
    margin-top: 5%;
    transition: all 0.3s;
    font-size: var(--button-font-size);
    color: var(--text-color);
    font-weight: 700;
    border: 3px solid var(--box-color);
  }

  .btn-left {
    background-color: var(--box-color);
  }

  .btn-left:hover {
    background-color: var(--box-color-hover);
    border-color: var(--box-color-hover);
  }

  .bg {
    position: absolute;
    width: 75%;
    bottom: 0;
    left: calc(-18% - 8%);
  }

  @media (max-width: 1024px) {
    .main-content-wrapper {
      max-width: 100%;
      width: 94%;
      height: 65vh;
      margin-top: -6rem;
    }

    .content {
      position: relative;
      flex-direction: column;
      margin-top: 1rem;
      gap: 5%;
    }

    .content-left {
      text-align: center;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      h2 {
        font-size: clamp(2rem, 2.5vw, 10vw);
        line-height: 1;
      }
    }

    .content-right {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .btn {
      width: 100%;
    }

    .bg {
      display: none;
    }
  }
`

export default MainPage
